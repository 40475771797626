import axiosClient from "../axiosClient";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

export default function useCities() {
  const cities = ref({});
  const city = ref({});
  const validationErrors = ref({});
  const isLoading = ref(false);

  const router = useRouter();
  const swal = inject("$swal");

  const getCities = async (
    page = 1,
    search_id = "",
    search_name_en = "",
    search_name_ar = "",
    search_country = "",
    search_global = "",
    order_column = "created_at",
    order_direction = "desc",
    per_page = 10
  ) => {
    axiosClient
      .get(
        "/cities?" +
          "page=" +
          page +
          "&search_id=" +
          search_id +
          "&search_name_en=" +
          search_name_en +
          "&search_name_ar=" +
          search_name_ar +
          "&search_country=" +
          search_country +
          "&search_global=" +
          search_global +
          "&order_column=" +
          order_column +
          "&order_direction=" +
          order_direction +
          "&per_page=" +
          per_page
      )
      .then((response) => {
        cities.value = response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          validationErrors.value = error.response.data.errors;

          swal({
            icon: "error",
            title: "Server error, please try again.",
          });
        }
      });

     
  };

  const getCity = async (id) => {
    await axiosClient.get("/cities/" + id).then((response) => {
      city.value = response.data.data;
    });
  };

  const storeCity = async (city) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    let serializedCity = new FormData();
    for (let item in city) {
      if (city.hasOwnProperty(item)) {
        serializedCity.append(item, city[item]);
      }
    }

    axiosClient
      .post("/cities", serializedCity)
      .then((response) => {
        router.push({ name: "cities.index" });
        swal({
          icon: "success",
          title: "City saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const updateCity = async (city) => {
    if (isLoading.value) return;

    isLoading.value = true;
    validationErrors.value = {};

    axiosClient
      .put("/cities/" + city.id, city)
      .then((response) => {
        router.push({ name: "cities.index" });
        swal({
          icon: "success",
          title: "City saved successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          validationErrors.value = error.response.data.errors;
        }
      })
      .finally(() => (isLoading.value = false));
  };

  const deleteCity = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#ef4444",
      timer: 20000,
      timerProgressBar: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete("/cities/" + id)
          .then((response) => {
            getCities();
            router.push({ name: "cities.index" });
            swal({
              icon: "success",
              title: "City deleted successfully",
            });
          })
          .catch((error) => {
            swal({
              icon: "error",
              title: "Something went wrong",
            });
          });
      }
    });
  };

  return {
    cities,
    city,
    getCities,
    getCity,
    storeCity,
    validationErrors,
    isLoading,
    updateCity,
    deleteCity,
  };
}
