<template>
  <div>
    <div class="pb-6">
      <div class="row align-items-center py-4 w-100">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta.title }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Menu -->
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                Filters
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <input v-model="search_name_en" class="form-control" placeholder="Search name" type="text" />
                  </div>
                  <div class="col-md-3">
                    <input v-model="search_username" class="form-control" placeholder="Search Username" type="text" />
                  </div>
                  <div class="col-md-3">
                    <input v-model="search_mobile" class="form-control" placeholder="Search Mobile" type="text" />
                  </div>
                  <div class="col-md-3">
                    <!-- TODO: filter by role id (pivot table) -->

                    <!-- <base-input name="search_role" label="">
                      <el-select v-model="search_role" filterable>
                        <el-option
                          v-for="option in roles"
                          :key="option.id"
                          :label="option.name_en"
                          :value="option.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="border-0 card-header d-flex justify-content-between">
                <div>
                  <div class="form-group d-flex flex-column justify-content-start">
                    <Dropdown class="w-100" :filter="true" :showClear="false" v-model="per_page"
                      :options="per_page_options" optionLabel="text" optionValue="value" />
                  </div>
                </div>
                <div>
                  <Button v-if="can('user_create')" label="New" @click.prevent="
              router.push({
                name: 'users.create',
              })
              " class="p-button-success" />
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('id')">
                          <div :class="{
              '': orderColumn === 'id',
            }">
                            ID
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'id' },
              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'id' },
              { 'pi pi-sort-alt': orderColumn != 'id' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('name_en')">
                          <div :class="{
              '': orderColumn === 'name_en',
            }">
                            Name
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'name_en' },
              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'name_en' },
              { 'pi pi-sort-alt': orderColumn != 'name_en' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('username')">
                          <div :class="{
              '': orderColumn === 'username',
            }">
                            Username
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'username' },
              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'username' },
              { 'pi pi-sort-alt': orderColumn != 'username' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('email')">
                          <div :class="{
              '': orderColumn === 'email',
            }">
                            Email
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'email' },
              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'email' },
              { 'pi pi-sort-alt': orderColumn != 'email' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex justify-content-between" @click="updateOrdering('mobile')">
                          <div :class="{
              '': orderColumn === 'mobile',
            }">
                            Mobile
                          </div>
                          <div role="button" class="select-none">
                            <i :class="[
              { 'pi pi-sort-amount-up-alt text-primary': orderDirection === 'desc' && orderColumn === 'mobile' },
              { 'pi pi-sort-amount-down-alt text-primary': orderDirection === 'asc' && orderColumn === 'mobile' },
              { 'pi pi-sort-alt': orderColumn != 'mobile' }]"></i>
                          </div>
                        </div>
                      </th>
                      <th>Role</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in users.data" :key="user.index">
                      <td class="text-sm font-weight-normal">
                        <span>{{ user.id }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ user.name_en }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ user.username }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ user.email }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <span>{{ "+" + user.mobilecode + " " + user.mobile }}</span>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <div v-for="role in user.roles" :key="role.index">
                          {{ role.name_en }}
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <Button class="p-button-info" icon="fas fa-eye" v-if="can('user_show')" @click.prevent="
              router.push({
                name: 'users.show',
                params: { id: user.id },
              })
              " />
                        <Button class="p-button-warning" icon="fas fa-pen-fancy" v-if="can('user_edit')" @click.prevent="
              router.push({
                name: 'users.edit',
                params: { id: user.id },
              })
              " />
                        <Button class="p-button-danger" icon="fas fa-trash" v-if="can('user_delete')"
                          @click.prevent="deleteUser(user.id)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="border-0 card-footer d-flex justify-content-between">
                <div>
                  <p class="text-muted text-sm mb-0">
                    Showing {{ users?.meta?.from }} to {{ users?.meta?.to }} of
                    {{ users?.meta?.total }}
                  </p>
                </div>
                <div>
                  <pagination :data="users" :limit="1" @pagination-change-page="(page) =>
                getUsers(
                  page,
                  search_id,
                  search_name_en,
                  search_name_ar,
                  search_username,
                  search_email,
                  search_mobile,
                  search_mobilecode,
                  search_role,
                  search_global,
                  orderColumn,
                  orderDirection,
                  per_page
                )
              " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import useUsers from "../../../composables/Users";
import useRoles from "../../../composables/Roles";
import { useAbility } from "@casl/vue";
import LaravelVuePagination from "../../../components/Pagination/LaravelVuePagination.vue";

export default {
  name: "form-components",
  components: {
    Pagination: LaravelVuePagination,
  },
  setup() {
    const page = 1;
    const per_page = ref(10);
    const per_page_options = ref([]);
    const search_id = ref("");
    const search_name_en = ref("");
    const search_name_ar = ref("");
    const search_username = ref("");
    const search_email = ref("");
    const search_mobile = ref("");
    const search_mobilecode = ref("");
    const search_role = ref("");
    const search_global = ref("");
    const orderColumn = ref("id");
    const orderDirection = ref("desc");

    const router = useRouter();
    const { users, getUsers, deleteUser } = useUsers();
    const { roles, getRoles } = useRoles();
    const { can } = useAbility();
    onMounted(async () => {
      await getUsers();
      await getRoles();
      per_page_options.value = [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ];
    });

    const updateOrdering = (column) => {
      orderColumn.value = column;
      orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
      getUsers(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_username.value,
        search_email.value,
        search_mobile.value,
        search_mobilecode.value,
        search_role.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    };
    watch(search_id, (current) => {
      getUsers(
        1,
        current,
        search_name_en.value,
        search_name_ar.value,
        search_username.value,
        search_email.value,
        search_mobile.value,
        search_mobilecode.value,
        search_role.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name_en, (current) => {
      getUsers(
        1,
        search_id.value,
        current,
        search_name_ar.value,
        search_username.value,
        search_email.value,
        search_mobile.value,
        search_mobilecode.value,
        search_role.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_name_ar, (current) => {
      getUsers(
        1,
        search_id.value,
        search_name_en.value,
        current,
        search_username.value,
        search_email.value,
        search_mobile.value,
        search_mobilecode.value,
        search_role.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_username, (current) => {
      getUsers(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        current,
        search_email.value,
        search_mobile.value,
        search_mobilecode.value,
        search_role.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_email, (current) => {
      getUsers(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_username.value,
        current,
        search_mobile.value,
        search_mobilecode.value,
        search_role.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_mobile, (current) => {
      getUsers(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_username.value,
        search_email.value,
        current,
        search_mobilecode.value,
        search_role.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_role, (current) => {
      getUsers(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_username.value,
        search_email.value,
        search_mobile.value,
        search_mobilecode.value,
        current,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(search_global, (current) => {
      getUsers(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_username.value,
        search_email.value,
        search_mobile.value,
        search_mobilecode.value,
        search_role.value,
        current,
        orderColumn.value,
        orderDirection.value,
        per_page.value
      );
    });
    watch(per_page, (current) => {
      getUsers(
        1,
        search_id.value,
        search_name_en.value,
        search_name_ar.value,
        search_username.value,
        search_email.value,
        search_mobile.value,
        search_mobilecode.value,
        search_role.value,
        search_global.value,
        orderColumn.value,
        orderDirection.value,
        current
      );
    });

    return {
      router,
      users,
      roles,
      getUsers,
      deleteUser,
      search_id,
      search_name_en,
      search_name_ar,
      search_username,
      search_email,
      search_mobile,
      search_mobilecode,
      search_role,
      search_global,
      orderColumn,
      orderDirection,
      updateOrdering,
      can,
      per_page_options,
      per_page,
      page,
    };
  },
};
</script>
<style scoped>
.c-info {
  color: dodgerblue;
}
</style>
