<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-primary py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Create an account</h1>
              <p class="text-lead text-white">Welcome to PXL.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <form name="register" id="register" @submit.prevent="registerUser(user)">
            <div class="card bg-white border-0">
              <div class="card-body px-lg-5 py-lg-5">
                <div class="text-center text-muted mb-4">
                  <small>Register with credentials</small>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <FileUpload name="avatar[]" :auto="true" :customUpload="true"
                      @uploader="storeAuthFiles($event, false, 'user_avatar', 30, 0, user.avatar, false)"
                      :multiple="true" accept="image/*">
                      <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                        <div class="d-flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                          <div class="d-flex gap-2">
                            <Button @click="chooseCallback()" icon="pi pi-images" class="p-button-rounded"></Button>
                          </div>
                        </div>
                      </template>
                      <template #empty>
                        <div v-if="!authProcessingFiles['user_avatar'] && user?.avatar && user?.avatar.length > 0"
                          class="d-flex justify-content-start align-items-center flex-wrap">
                          <div v-for="(image, index) in user?.avatar" :key="index" class="p-2 position-relative"
                            style="height:150px; width: 150px;">
                            <Image :src="image.original_url ? image.original_url : '/img/brand/default-logo.png'"
                              alt="upload" class="w-100 h-100" imageClass="image-cover" preview />
                            <div
                              class="position-absolute d-flex align-items-center justify-content-center rounded-circle pointer"
                              style="top: 0; right: 0; width: 25px; height: 25px; background: var(--primary-pxl); color: #fff"
                              @click.prevent="
                                removeFromArray(user?.avatar, index)
                                ">
                              <i class="pi pi-times"></i>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="authProcessingFiles['user_avatar']"
                          class="d-flex justify-content-center align-items-center">
                          <ProgressSpinner style="width:20px;height:20px" strokeWidth="4" animationDuration=".5s" />
                        </div>
                        <div v-else class="d-flex align-items-center justify-content-center flex-column">
                          <Image src="/img/upload-icon.svg" alt="upload" width="150" />
                          <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                        </div>
                      </template>
                    </FileUpload>
                    <div class="row" v-for="message in validationErrors['avatar']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 mt-4">
                    <label class="form-control-label">Name
                      <md />
                    </label>
                    <input v-model="user.name_en" class="form-control" id="name_en" type="text" name="name_en" />
                    <div class="row" v-for="message in validationErrors['name_en']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 mt-4">
                    <label class="form-control-label">username
                      <md />
                    </label>
                    <input v-model="user.username" class="form-control" id="username" type="text" name="username" />
                    <div class="row" v-for="message in validationErrors['username']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 mt-4">
                    <div v-if="meta?.country && meta?.country.length > 0"
                      class="d-flex flex-column justify-content-start">
                      <label class="form-control-label">Country</label>
                      <Dropdown v-model="user.country_id" :filter="true" :options="meta?.country" optionLabel="name_en"
                        optionValue="id" placeholder="Select a country" />
                      <div class="row" v-for="message in validationErrors['country_id']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-4">
                    <div v-if="meta?.city" class="d-flex flex-column justify-content-start">
                      <label class="form-control-label">City</label>
                      <Dropdown class="w-100" v-model="user.city_id" :filter="true" :options="cities?.data"
                        optionLabel="name_en" optionValue="id" placeholder="Select a city" />
                      <div class="row" v-for="message in validationErrors['city_id']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">
                      Mobile
                      <md />
                    </label>
                    <div class="mobile-input-container">
                      <Dropdown v-model="user.mobilecode" :filter="true" :options="meta?.country"
                        optionLabel="mobilecode" optionValue="mobilecode" placeholder="Code" style="height: 3rem;" />
                      <div v-if="!user.mobilecode">
                        <div class="row" v-for="message in validationErrors['mobilecode']" :key="message.index">
                          <span class="text-danger">
                            {{ message }}
                          </span>
                        </div>
                      </div>
                      <!-- mobile number -->
                      <input v-model="user.mobile" class="form-control mobile-input" id="mobile" type="tel"
                        name="mobile" placeholder="33330123" maxlength="9" />
                      <div class="row" v-for="message in validationErrors['mobile']" :key="message.index">
                        <span class="text-danger">
                          {{ message }}
                        </span>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Email
                      <md />
                    </label>
                    <input v-model="user.email" class="form-control" id="email" type="text" name="email" />
                    <div class="row" v-for="message in validationErrors['email']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 mt-4">
                    <label class="form-control-label">Villa</label>
                    <input v-model="user.villa" class="form-control" id="address-villa" type="text"
                      name="address-villa" />
                    <div class="row" v-for="message in validationErrors['villa']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 mt-4">
                    <label class="form-control-label">Flat</label>
                    <input v-model="user.flat" class="form-control" id="address-flat" type="text" name="address-flat" />
                    <div class="row" v-for="message in validationErrors['flat']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 mt-4">
                    <label class="form-control-label">Road</label>
                    <input v-model="user.road" class="form-control" id="address-road" type="text" name="address-road" />
                    <div class="row" v-for="message in validationErrors['road']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-3 mt-4">
                    <label class="form-control-label">Block</label>
                    <input v-model="user.block" class="form-control" id="address-block" type="text"
                      name="address-block" />
                    <div class="row" v-for="message in validationErrors['block']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Post Code</label>
                    <input v-model="user.post_code" class="form-control" id="address-post_code" type="text"
                      name="address-post_code" />
                    <div class="row" v-for="message in validationErrors['post_code']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Password
                      <md />
                    </label>
                    <input v-model="user.password" class="form-control" id="password" type="password" name="password" />
                    <div class="row" v-for="message in validationErrors['password']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <label class="form-control-label">Confirm Password
                      <md />
                    </label>
                    <input v-model="user.password_confirmation" class="form-control" id="password_confirmation"
                      type="password" name="password_confirmation" />
                    <div class="row" v-for="message in validationErrors['password_confirmation']" :key="message.index">
                      <span class="text-danger">
                        {{ message }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 d-flex justify-content-between">
                <base-button @click.prevent="
                  router.push({
                    name: 'Login',
                  })
                  " size="m" type="secondary">Cancel</base-button>
                <base-button native-type="submit" size="m" type="success">Register</base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, onServerPrefetch, reactive, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuth } from "@/composables/auth";
import useCities from "@/composables/Cities";
import { removeFromArray } from "@/helpers/Functions";

const user = reactive({
  avatar: [],
  about_en: "",
  about_ar: "",
  socials: [],
  name_en: "",
  name_ar: "",
  username: "",
  email: "",
  iban: "",
  villa: "",
  flat: "",
  road: "",
  block: "",
  country_id: 18,
  city_id: null,
  commission: "",
  rate: "",
  post_code: "",
  status_id: "",
  type_id: "",
  roles: [],
  password: "",
  password_confirmation: "",
  mobilecode: ""
});

const { meta, getAuthMeta, registerUser, storeAuthFiles, validationErrors, authProcessingFiles } = useAuth();
const { cities, getCities } = useCities();
const router = useRouter();


onServerPrefetch(async () => {
  console.log("fetching cities");
  await getCities(
    1,
    "",
    "",
    "",
    user.country_id,
    "",
    "name_en",
    "asc",
    50000
  );

  console.log("cities fetched ? ", cities.value?.data);

});
onMounted(async () => {
  console.log("mounting cities");

  getAuthMeta();
  getCities(
    1,
    "",
    "",
    "",
    user.country_id,
    "",
    "name_en",
    "asc",
    50000
  );
});


watch(() => {
  const countriesArray = meta.value.country;
  if (countriesArray && countriesArray.length > 0) {
    const getMobileCode = countriesArray.find((element) => {
      return element.id === user.country_id;
    });

    if (getMobileCode) {
      user.mobilecode = getMobileCode.mobilecode;
    }
  }
})

// get cities after change [country_id]
watch(() => user.country_id, async () => {
  await getCities(
    1,
    "",
    "",
    "",
    user.country_id,
    "",
    "name_en",
    "asc",
    50000)
}
  , {
    deep: true,
  })

// inject cities in meta.value.city
watch(() => cities.value.data, async () => {
  // console.log("cities before", cities.value?.data);
  console.log("Updating meta.city");
  if (cities.value?.data) {
    meta.value.city = cities.value.data;
    console.log("meta.city updated:", meta.value.city);
  }
  // console.log("cities after", cities.value?.data);
  // console.log("here meta after", meta.value.city);
}, {
  deep: true,
})


</script>
<style scoped>
.mobile-input-container {
  display: flex;
  align-items: center;
}

.mobile-input {
  flex-grow: 1;
  /* Allow the first input to grow and take the available space */
  margin-right: 0.5rem;
  /* Add some spacing between the inputs */
}
</style>
