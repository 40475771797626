<template>
  <div>
    <!-- <div class="bg-default py-5">&nbsp;</div> -->
    <!-- Page content -->
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4 mt-5">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="d-flex justify-content-start align-items-center">
                <img id="artwork-image" class="rounded-circle mr-4 border"
                  style="object-fit: cover; width: 80px; height: 80px" :src="user.avatar && user?.avatar[0]?.url
                    ? user?.avatar[0]?.url
                    : 'img/user-defualt.svg'
                    " alt="" loading="lazy" />
                <div class="d-flex flex-column">
                  <span class="header-1-p">
                    {{ user.username }}
                  </span>
                  <span class="text-2-s">
                    {{ user.name_en }}
                  </span>
                  <span class="text-4-g">
                    Join since {{ daysAgo(user.created_at) }}
                  </span>
                </div>
              </div>
              <div class="mt-3 w-100">
                <p class="text-3-g">
                  {{ user.about_en }}
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex justify-content-start justify-content-md-end align-items-center mt-4 mt-lg-0">
                <!-- NEW ARTWORK sections in settings -->
                <!-- <button class="btn-custom-light" @click.prevent="
                  router.push({
                    name: 'AddArtwork',
                  })
                ">
                  <span class="text-bold-3-s">NEW ARTWORK</span>
                  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" class="ml-3">
                    <path id="Path_270" data-name="Path 270" d="M5.636,18.364a9,9,0,1,1,12.728,0" fill="none"
                      stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line id="Line_19" data-name="Line 19" y1="9" transform="translate(12 11)" fill="none"
                      stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_271" data-name="Path 271" d="M15,14l-3-3L9,14" fill="none" stroke="#ec5d42"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <rect id="Rectangle_81" data-name="Rectangle 81" width="24" height="24" fill="none" />
                  </svg>
                </button> -->
                <!-- <button class="btn-custom-light ml-3" @click.prevent="
                  router.push({
                    name: 'Themes',
                  })
                ">
                  <span class="text-bold-3-s">VIEW SHOP</span>
                  <svg id="Arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    class="ml-3">
                    <g id="Group_11" data-name="Group 11" transform="translate(5 7)">
                      <path id="Path_49" data-name="Path 49" d="M19,12H5" transform="translate(-5 -7)" fill="none"
                        stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                      <path id="Path_50" data-name="Path 50" d="M14,17l5-5" transform="translate(-5 -7)" fill="none"
                        stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                      <path id="Path_51" data-name="Path 51" d="M14,7l5,5" transform="translate(-5 -7)" fill="none"
                        stroke="#ec5d42" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </g>
                    <path id="Path_52" data-name="Path 52" d="M0,0H24V24H0Z" fill="none" />
                  </svg>
                </button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hr class="m-0" />
        </div>
        <div class="col-12 col-lg-2 col-sm-12 mt-5 mb-5 d-lg-block">
          <div class="side-menu">
            <div class="title">
              <span class="header-3-p">My store</span>
            </div>
            <div class="menu-list">
              <div @click.prevent="switchTab('profile')" class="list-item text-m-3-s"
                :class="{ active: activeTab == 'profile' }">
                Profile
              </div>
              <div @click.prevent="switchTab('artwork')" class="list-item text-m-3-s"
                :class="{ active: activeTab == 'artwork' }">
                Artwork
              </div>
              <div @click.prevent="switchTab('sales')" class="list-item text-m-3-s"
                :class="{ active: activeTab == 'sales' }">
                Sales
              </div>
            </div>
            <div class="title">
              <span class="header-3-p">Account</span>
            </div>
            <div class="menu-list">
              <div @click.prevent="switchTab('notifications')" class="list-item text-m-3-s"
                :class="{ active: activeTab == 'notifications' }">
                Notifications <Badge v-if="unreadNotifications > 0" :value="unreadNotifications" severity="danger">
                </Badge>
              </div>
              <div @click.prevent="switchTab('order-history')" class="list-item text-m-3-s"
                :class="{ active: activeTab == 'order-history' }">
                Order history
              </div>
              <div @click.prevent="switchTab('favorites')" class="list-item text-m-3-s"
                :class="{ active: activeTab == 'favorites' }">
                Favorites
              </div>
              <div @click.prevent="switchTab('settings')" class="list-item text-m-3-s"
                :class="{ active: activeTab == 'settings' }">
                Settings
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-10 mt-5 mb-5">
          <div v-show="activeTab == 'profile'" id="profile" class="tab-content"
            :class="{ active: activeTab == 'profile' }">
            <div class="row">
              <div class="col-md-12">
                <div v-if="user?.artworks && user?.artworks?.data" class="row mb-5 mx-2 mx-md-0 justify-content-start">
                  <div v-for="artwork in user?.artworks?.data" :key="artwork.index"
                    class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-0 m-3">
                    <div>
                      <div class="card card-custom bg-dark text-white border-0 m-0 ml-0 mr-0 mb-3 mx-auto mx-md-0" style="cursor: pointer;">
                        <img class="card-img" style="object-fit: contain; width: 100%; height: 100%;"
                          :style="`${Object.keys(artwork.initial).length > 0 && artwork.initial[Object.keys(artwork.initial)[0]]?.color ? 'background-color: ' + artwork.initial[Object.keys(artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                          :src="artwork?.cover_image && artwork?.cover_image[0]?.webp
                    ? artwork?.cover_image[0]?.webp
                    : artwork?.image[0]?.webp
                      ? artwork?.image[0]?.webp
                      : '/img/placeholders/block.png'
                    " alt="Card image" @click.prevent="
                    router.push({
                      name: 'Artwork',
                      params: { id: artwork?.slug },
                    })
                    " />
                        <div class="btn-fav">
                          <img @click.prevent="addToWishlist(artwork?.id, isLoggedIn)" :src="wishlistIDs.includes(artwork?.id)
                    ? 'img/favorite.svg'
                    : 'img/non-favorite.svg'
                    " />
                        </div>
                        <div class="img-footer">
                          <span v-if="formatDateYYYYMMDD(artwork?.created_at) ==
                    formatDateYYYYMMDD(Date.now())
                    " class="badge badge-secondary p-2">
                            New
                          </span>
                        </div>
                      </div>
                      <div @click.prevent="
                    router.push({
                      name: 'Artwork',
                      params: { id: artwork?.slug },
                    })
                    " class="d-flex align-items-start flex-column m-pointer mx-auto mx-md-0 card-custom-info">
                        <span class="header-3-s one-line-text">
                          {{ artwork?.name_en }}
                        </span>
                        <span class="text-4-g one-line-text">
                          {{ artwork?.user?.name_en }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="artwork-pages">
                  <div v-if="user?.artworks" class="
                              w-100
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-3
                            ">
                    <div>
                      <p class="text-muted text-sm mb-0">
                        Showing {{ user?.artworks?.from }} to
                        {{ user?.artworks?.to }} of
                        {{ user?.artworks?.total }}
                      </p>
                    </div>
                    <div>
                      <LaravelVuePagination :data="user?.artworks" :limit="1" @pagination-change-page="(page) =>
                    getUser(
                      logedinUser.id,
                      page,
                      search_global,
                      orderColumn,
                      orderDirection,
                      per_page
                    )
                    " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeTab == 'artwork'" id="artwork" class="tab-content"
            :class="{ active: activeTab == 'artwork' }">
            <div class="row">
              <div class="col-md-12 mb-3">
                <div v-if="user?.artworks?.data" class="d-flex justify-content-between">
                  <div class="
                              d-none d-lg-block
                            ">
                    <div class="
                              artwork-no
                              text-3-g
                              d-flex
                              justify-content-between
                              align-items-center">
                      {{ user?.artworks?.total }} Artworks
                    </div>
                  </div>
                  <div class="
                              artwork-filters
                              d-flex
                              justify-content-between
                              align-items-center
                            ">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          <svg id="Search" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24">
                            <path id="Path_6" data-name="Path 6" d="M0,0H24V24H0Z" fill="none" />
                            <path id="Path_7" data-name="Path 7"
                              d="M14.193,5.582a5.971,5.971,0,1,1-8.444,0,5.971,5.971,0,0,1,8.444,0"
                              transform="translate(0 0)" fill="none" stroke="#837f7a" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2" />
                            <path id="Path_8" data-name="Path 8" d="M14.15,14.06,20,19.99" transform="translate(0 0)"
                              fill="none" stroke="#837f7a" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2" />
                          </svg>
                        </span>
                      </div>
                      <input v-model="search_global" type="text" class="form-control" placeholder="Search title or tag"
                        style="height: 42px;" />
                    </div>
                    <div class="sort d-flex justify-content-end align-items-center ml-3 ml-lg-0">
                      <div v-if="user?.artworks?.data" class="header-3-p mr-2 ml-3 d-none d-lg-block"
                        style="width: 6rem">
                        Sort by
                      </div>
                      <div v-if="user?.artworks?.data">
                        <Dropdown v-model="orderColumn" :options="orderColumnOptions" optionLabel="text"
                          optionValue="value" placeholder="Sort By" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="artwork-list">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <tbody v-for="(artwork, index) in user?.artworks?.data" :key="index">
                        <tr data-toggle="tooltip" data-placement="top" :title="artwork && artwork.notes[0]
                    ? artwork.notes[0].title_en +
                    ': ' +
                    artwork.notes[0].content_en
                    : 'No notes for this artwork'
                    ">
                          <td class="col-1 pl-0 pr-0 align-middle d-none d-lg-table-cell">
                            <InputSwitch :id="index" v-model="artwork.is_active"
                              :disabled="artwork.status_id == 3 || artwork.status_id == 1 ? true : false"
                              @click.prevent="updateIsActive(
                    artwork.id,
                    artwork.is_active,
                    artwork
                  )" />
                          </td>
                          <td class="col-2 col-lg-1 align-middle pl-0 pr-0">
                            <img id="artwork-image" class="img-fluid" style="object-fit: contain"
                              :style="`${Object.keys(artwork.initial).length > 0 && artwork.initial[Object.keys(artwork.initial)[0]]?.color ? 'background-color: ' + artwork.initial[Object.keys(artwork.initial)[0]]?.color : 'background-color: #fff;'}`"
                              :src="artwork.cover_image && artwork?.cover_image[0]?.url
                    ? artwork?.cover_image[0]?.url
                    : artwork?.image[0]?.webp
                      ? artwork?.image[0]?.webp
                      : '/img/placeholders/block.png'
                    " alt="" loading="lazy" />
                          </td>
                          <td class="col-3 align-middle">
                            <p class="header-2-s">
                              {{ limit(artwork.name_en, 20) }}
                            </p>
                            <p class="text-4-g d-none d-lg-block">
                              {{ limit(artwork.description_en, 30) }}
                            </p>
                            <p class="d-block d-lg-none">
                              <span :class="{
                    'badge-warning': artwork.status_id == 1,
                    'badge-success': artwork.status_id == 2,
                    'badge-danger': artwork.status_id == 3,
                  }" class="badge badge-lg mr-2" style="width: 84px">
                                {{ artwork.status.name_en }}
                                <svg v-if="artwork.status_id == 3" id="warning" xmlns="http://www.w3.org/2000/svg"
                                  width="15" height="15" viewBox="0 0 15 15">
                                  <path id="Path_319" data-name="Path 319" d="M0,0H15V15H0Z" fill="none" />
                                  <path id="Path_320" data-name="Path 320"
                                    d="M8.625,3h0A5.625,5.625,0,0,1,14.25,8.625h0A5.625,5.625,0,0,1,8.625,14.25h0A5.625,5.625,0,0,1,3,8.625H3A5.625,5.625,0,0,1,8.625,3Z"
                                    transform="translate(-1.125 -1.125)" fill="none" stroke="#d55f5f"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                  <path id="Path_321" data-name="Path 321" d="M12,10.625V7.5"
                                    transform="translate(-4.5 -2.813)" fill="none" stroke="#d55f5f"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                  <path id="Path_322" data-name="Path 322"
                                    d="M11.906,16a.156.156,0,1,0,.157.156A.155.155,0,0,0,11.906,16"
                                    transform="translate(-4.406 -6)" fill="none" stroke="#d55f5f" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="1.5" />
                                </svg>
                              </span>
                            </p>
                          </td>
                          <td class="col-3 align-middle d-none d-lg-table-cell">
                            <p class="text-4-g">
                              {{ formatDateYYYYMMDD(artwork.created_at) }}
                            </p>
                          </td>
                          <td class="col-2 align-middle d-none d-lg-table-cell">
                            <span :class="{
                    'badge-warning': artwork.status_id == 1,
                    'badge-success': artwork.status_id == 2,
                    'badge-danger': artwork.status_id == 3,
                  }" class="badge badge-lg mr-2" style="width: 84px">
                              {{ artwork.status.name_en }}
                              <svg v-if="artwork.status_id == 3" id="warning" xmlns="http://www.w3.org/2000/svg"
                                width="15" height="15" viewBox="0 0 15 15">
                                <path id="Path_319" data-name="Path 319" d="M0,0H15V15H0Z" fill="none" />
                                <path id="Path_320" data-name="Path 320"
                                  d="M8.625,3h0A5.625,5.625,0,0,1,14.25,8.625h0A5.625,5.625,0,0,1,8.625,14.25h0A5.625,5.625,0,0,1,3,8.625H3A5.625,5.625,0,0,1,8.625,3Z"
                                  transform="translate(-1.125 -1.125)" fill="none" stroke="#d55f5f"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <path id="Path_321" data-name="Path 321" d="M12,10.625V7.5"
                                  transform="translate(-4.5 -2.813)" fill="none" stroke="#d55f5f" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="1.5" />
                                <path id="Path_322" data-name="Path 322"
                                  d="M11.906,16a.156.156,0,1,0,.157.156A.155.155,0,0,0,11.906,16"
                                  transform="translate(-4.406 -6)" fill="none" stroke="#d55f5f" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="1.5" />
                              </svg>
                            </span>
                          </td>
                          <td class="col-2 pr-0 align-middle">
                            <div class="d-none d-lg-block">
                              <div class="d-flex justify-content-end">
                                <button class="btn-custom-light" v-if="can('artwork_edit')" @click.prevent="
                    router.push({
                      name: 'EditArtwork',
                      params: { slug: artwork?.slug },
                    })
                    ">
                                  <svg id="pen-edit.17" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24">
                                    <path id="Path_316" data-name="Path 316" d="M0,0H24V24H0Z" fill="none" />
                                    <path id="Path_317" data-name="Path 317" d="M17.54,10.12,13.88,6.46" fill="none"
                                      stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" />
                                    <path id="Path_318" data-name="Path 318"
                                      d="M6.251,21H3V17.749a1,1,0,0,1,.293-.707L16.627,3.707a1,1,0,0,1,1.414,0l2.251,2.251a1,1,0,0,1,0,1.414L6.958,20.707A1,1,0,0,1,6.251,21Z"
                                      fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" />
                                  </svg>
                                </button>
                                <button class="btn-custom-light ml-2" v-if="can('artwork_delete')"
                                  @click.prevent="handleDeleteArtwork(artwork.id, 'user')">
                                  <svg id="trash-delete-bin" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24">
                                    <path id="Path_315" data-name="Path 315"
                                      d="M6,9V19H6a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9m-4,1v7m-4-7v7M4.5,6h15M8,6l.544-1.632h0A2,2,0,0,1,10.442,3h3.116a2,2,0,0,1,1.9,1.368L16,6"
                                      fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" />
                                    <rect id="Rectangle_96" data-name="Rectangle 96" width="24" height="24"
                                      fill="none" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div class="d-block d-lg-none text-right mr-3">
                              <a href="#" class="nav-link p-0 pl-2 mt-1"
                                @click.prevent="toggleArtworkMenu($event, index)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15">
                                  <g id="Group_552" data-name="Group 552" transform="translate(-10.5 19.5) rotate(-90)">
                                    <path id="Path_341" data-name="Path 341"
                                      d="M18.5,12h0a.5.5,0,1,1-.5-.5h0a.5.5,0,0,1,.5.5" fill="none" stroke="#323232"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    <path id="Path_342" data-name="Path 342"
                                      d="M12.5,12h0a.5.5,0,1,1-.5-.5h0a.5.5,0,0,1,.5.5" fill="none" stroke="#323232"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    <path id="Path_343" data-name="Path 343"
                                      d="M6.5,12h0a.5.5,0,1,1-.5-.5H6a.5.5,0,0,1,.5.5" fill="none" stroke="#323232"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                  </g>
                                </svg>
                              </a>
                              <OverlayPanel ref="ArtworkMenu" appendTo="body">
                                <a href="#!" v-if="can('artwork_edit')"
                                  @click.prevent="updateIsActive(artwork.id, artwork.is_active, artwork)"
                                  class="dropdown-item">
                                  <div v-if="artwork.is_active">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                      viewBox="0 0 512 512">
                                      <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                                      <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
                                    </svg>
                                    <span class="text-4-s ml-2">Deactivate</span>
                                  </div>
                                  <div v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                      viewBox="0 0 512 512">
                                      <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                                      <path
                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z" />
                                    </svg>
                                    <span class="text-4-s ml-2">Activate</span>
                                  </div>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a href="#!" v-if="can('artwork_edit')" @click.prevent="router.push({
                    name: 'EditArtwork',
                    params: { slug: artwork?.slug },
                  })" class="dropdown-item">
                                  <svg id="pen-edit.17" xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                    viewBox="0 0 24 24">
                                    <path id="Path_316" data-name="Path 316" d="M0,0H24V24H0Z" fill="none" />
                                    <path id="Path_317" data-name="Path 317" d="M17.54,10.12,13.88,6.46" fill="none"
                                      stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" />
                                    <path id="Path_318" data-name="Path 318"
                                      d="M6.251,21H3V17.749a1,1,0,0,1,.293-.707L16.627,3.707a1,1,0,0,1,1.414,0l2.251,2.251a1,1,0,0,1,0,1.414L6.958,20.707A1,1,0,0,1,6.251,21Z"
                                      fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" />
                                  </svg>
                                  <span class="text-4-s">Edit</span>
                                </a>
                                <div class="dropdown-divider"></div>
                                <a href="#!" v-if="can('artwork_delete')"
                                  @click.prevent="deleteArtwork(artwork.id, 'user')" class="dropdown-item">
                                  <svg id="trash-delete-bin" xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                    viewBox="0 0 24 24">
                                    <path id="Path_315" data-name="Path 315"
                                      d="M6,9V19H6a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9m-4,1v7m-4-7v7M4.5,6h15M8,6l.544-1.632h0A2,2,0,0,1,10.442,3h3.116a2,2,0,0,1,1.9,1.368L16,6"
                                      fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" />
                                    <rect id="Rectangle_96" data-name="Rectangle 96" width="24" height="24"
                                      fill="none" />
                                  </svg>
                                  <span class="text-4-s">Delete</span>
                                </a>
                              </OverlayPanel>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="artwork && artwork.notes[0] && artwork.status_id == 3" class="bg-danger">
                          <td colspan="6">
                            <p class="text-white m-0">
                              {{ artwork.notes[0].title_en + ': ' + artwork.notes[0].content_en }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="artwork-pages">
                  <div v-if="user?.artworks" class="
                              w-100
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-3
                            ">
                    <div>
                      <p class="text-muted text-sm mb-0">
                        Showing {{ user?.artworks?.from }} to
                        {{ user?.artworks?.to }} of
                        {{ user?.artworks?.total }}
                      </p>
                    </div>
                    <div>
                      <LaravelVuePagination :data="user?.artworks" :limit="1" @pagination-change-page="(page) =>
                    getUser(
                      logedinUser.id,
                      page,
                      search_global,
                      orderColumn,
                      orderDirection,
                      per_page
                    )
                    " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeTab == 'sales'" id="sales" class="tab-content" :class="{ active: activeTab == 'sales' }">
            <div class="row">
              <div class="col-md-12 mb-3">
                <p v-if="user && user?.sales" class="header-3-s">
                  Sales
                  <span class="badge badge-lg badge-primary">{{
                    user?.sales?.total
                  }}</span>
                  Total Commission
                  <span class="badge badge-lg badge-primary">BHD {{ user?.totalCommission.toFixed(3) }}</span>
                  Pending Commission
                  <span class="badge badge-lg badge-primary">BHD {{ user?.pendingCommission.toFixed(3) }}</span>
                </p>
              </div>
              <div class="col-md-12">
                <div v-if="user?.sales" class="table-responsive">
                  <table class="table table-flush">
                    <tbody>
                      <tr v-for="(sale, index) in user?.sales?.data" :key="index">
                        <td class="col-12 p-0 pb-2 border-0">
                          <div class="d-flex flex-column">
                            <div class="
                                        d-flex
                                        justify-content-start
                                        align-items-center
                                        mb-2
                                        border-bottom border-top
                                      ">
                              <p class="header-3-p px-3">
                                {{ index + 1 }}
                              </p>
                              <span v-if="sale.is_paid == 1" class="badge badge-lg badge-success"
                                style="width: 110px">Recived</span>
                              <span v-else-if="sale.is_paid == 0" class="badge badge-lg badge-info"
                                style="width: 110px">Pending</span>
                              <p class="header-4-s px-1">Date:</p>
                              <span class="text-4-g px-1">{{
                    formatDateYYYYMMDD(sale.date)
                  }}</span>
                              <p class="header-4-s px-1">Ref:</p>
                              <span class="text-4-g px-1">{{
                      sale?.reference
                    }}</span>
                              <p class="header-4-s px-1">Commission</p>
                              <span class="text-4-g px-1">BHD {{ sale?.commission }}</span>
                            </div>
                            <div class="d-flex justify-content-start flex-wrap">
                              <div v-for="item in sale?.items" :key="item.index"
                                class="mr-2 d-flex justify-content-start border">
                                <img id="artwork-image" class="img-fluid" style="object-fit: contain; width: 100px"
                                  :src="item?.item_image ? item?.item_image : '/placeholders/block-b.png'" alt=""
                                  loading="lazy" />
                                <div class="details px-3 py-1">
                                  <div v-for="(option, index) in item?.options" :key="option.index"
                                    class="d-flex align-items-center">
                                    <p class="header-4-s text-capitalize">
                                      {{ index }}:&nbsp;
                                    </p>
                                    <span v-if="index == 'color'" class="color"
                                      :style="[{ 'background-color': option }]"></span>
                                    <span class="text-4-g">{{ option }}</span>
                                  </div>
                                  <div class="d-flex align-items-center">
                                    <p class="header-4-s text-capitalize">
                                      Qty:&nbsp;
                                    </p>
                                    <span class="text-4-g">{{
                    item.quantity
                  }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12">
                <div class="sales-pages">
                  <div v-if="user?.sales" class="
                              w-100
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-3
                            ">
                    <div>
                      <p class="text-muted text-sm mb-0">
                        Showing {{ user?.sales?.from }} to
                        {{ user?.sales?.to }} of
                        {{ user?.sales?.total }}
                      </p>
                    </div>
                    <div>
                      <LaravelVuePagination :data="user?.sales" :limit="1" @pagination-change-page="(page) =>
                    getUser(
                      logedinUser.id,
                      page,
                      search_global,
                      orderColumn,
                      orderDirection,
                      per_page
                    )
                    " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeTab == 'notifications'" id="notifications" class="tab-content"
            :class="{ active: activeTab == 'notifications' }">
            <div class="row">
              <div v-if="user?.notifications" class="col-md-12">
                <div v-for="notification in user?.notifications" :key="notification.index" class="notification">
                  <div class="details row">
                    <div class="one col-md-10 d-flex flex-column">
                      <div class="title">{{ notification.title_en }}</div>
                      <div class="content">{{ notification.content_en }}</div>
                    </div>
                    <div class="
                                col-md-2
                                two
                                ml-auto
                                d-flex
                                justify-content-end
                                align-items-center
                              ">
                      <div class="time">
                        {{ daysAgo(notification.created_at) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="col-md-12">
                <p class="text-1-g">There are no notifications</p>
              </div>
            </div>
          </div>
          <div v-show="activeTab == 'order-history'" id="order-histor" class="tab-content"
            :class="{ active: activeTab == 'order-history' }">
            <div class="row">
              <div class="col-md-12 mb-3">
                <p v-if="user && user?.orders" class="header-3-s">
                  Orders
                  <span class="badge badge-lg badge-primary">{{
                    user?.orders?.total
                  }}</span>
                </p>
              </div>
              <div class="col-md-12">
                <div v-if="user?.orders" class="table-responsive">
                  <table class="table table-flush">
                    <tbody>
                      <tr v-for="(order, index) in user?.orders?.data" :key="index">
                        <td class="col-12 p-0 pb-2 border-0">
                          <div class="d-flex flex-column">
                            <div class="
                                        d-flex
                                        justify-content-start
                                        align-items-center
                                        mb-2
                                        border-bottom border-top
                                      ">
                              <p class="header-3-p px-3">
                                {{ index + 1 }}
                              </p>
                              <span :class="{
                    'badge-info':
                      order.status_id == 1 ||   // Pending
                      order.status_id == 3, // Printing
                    'badge-green':
                      order.status_id == 2, // Paid
                    'badge-success': order.status_id == 5, // Completed
                    'badge-danger':
                      order.status_id == 6 ||  // Cancelled
                      order.status_id == 9,  // Failed
                    'badge-warning':
                      order.status_id == 7 ||  // refunded
                      order.status_id == 8 ||  // On hold
                      order.status_id == 4,    // delivering
                  }" class="badge badge-lg" style="width: 110px">{{ order?.status?.name_en }}</span>
                              <p class="header-4-s px-1">Date:</p>
                              <span class="text-4-g px-1">{{
                    formatDateYYYYMMDD(order.date)
                  }}</span>
                              <p class="header-4-s px-1">Ref:</p>
                              <span class="text-4-g px-1">{{
                      order?.reference
                    }}</span>
                              <p class="header-4-s px-1">Amount</p>
                              <span class="text-4-g px-1">BHD {{ order?.total }}</span>
                            </div>
                            <div class="d-flex justify-content-start flex-wrap">
                              <div v-for="item in order?.items" :key="item.index"
                                class="mr-2 d-flex justify-content-start border">
                                <img id="artwork-image" class="img-fluid" style="object-fit: contain; width: 100px"
                                  :src="item?.item_image ? item?.item_image : '/placeholders/block-b.png'" alt=""
                                  loading="lazy" />
                                <div class="details px-3 py-1">
                                  <div v-for="(option, index) in item?.options" :key="option.index"
                                    class="d-flex align-items-center">
                                    <p class="header-4-s text-capitalize">
                                      {{ index }}:&nbsp;
                                    </p>
                                    <span v-if="index == 'color'" class="color"
                                      :style="[{ 'background-color': option }]"></span>
                                    <span class="text-4-g">{{ option }}</span>
                                  </div>
                                  <div class="d-flex align-items-center">
                                    <p class="header-4-s text-capitalize">
                                      Qty:&nbsp;
                                    </p>
                                    <span class="text-4-g">{{
                    item.quantity
                  }}</span>
                                  </div>
                                </div>
                              </div>
                              <div class="
                                          ml-auto
                                          d-flex
                                          align-items-center
                                          justify-content-end
                                        ">
                                <button v-if="order?.status_id < 3" class="btn-custom-light px-3" @click.prevent="
                    cancelOrderHandle(order?.id, order?.status_id, index)
                    ">
                                  <p class="header-2-s">Cancel</p>
                                </button>
                                <button v-if="order?.status_id == 1" class="btn-custom-light px-3 ml-3"
                                  data-toggle="modal" :data-target="'#pay-order-' + order.id">
                                  <p class="header-2-s">Pay</p>
                                </button>
                                <div>
                                  <div class="modal fade" :id="'pay-order-' + order.id" tabindex="-1" role="dialog"
                                    aria-labelledby="pay-order" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <p class="header-1-p">
                                            Order
                                            <span class="text-1-s">#{{ order?.reference }}</span>
                                          </p>
                                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <div class="row">
                                            <div class="col-md-12">
                                              <p class="text-3-s">
                                                How would you pay the order?
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn-custom-light px-3 ml-3" data-dismiss="modal">
                                            <p class="header-2-s">Cancel</p>
                                          </button>
                                          <div v-for="method in userMeta.payment_methods" :key="method.index">
                                            <div v-if="method.name_en != 'Cash'">
                                              <button class="btn-custom-light px-3 ml-3" data-dismiss="modal"
                                                @click.prevent="
                    payOrder(
                      order?.id,
                      method.name_en,
                      order?.status_id
                    )
                    ">
                                                <p class="header-2-s">
                                                  {{ method.name_en }}
                                                </p>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12">
                <div class="orders-pages">
                  <div v-if="user?.orders" class="
                              w-100
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-3
                            ">
                    <div>
                      <p class="text-muted text-sm mb-0">
                        Showing {{ user?.orders?.from }} to
                        {{ user?.orders?.to }} of
                        {{ user?.orders?.total }}
                      </p>
                    </div>
                    <div>
                      <LaravelVuePagination :data="user?.orders" :limit="1" @pagination-change-page="(page) =>
                    getUser(
                      logedinUser.id,
                      page,
                      search_global,
                      orderColumn,
                      orderDirection,
                      per_page
                    )
                    " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeTab == 'favorites'" id="favorites" class="tab-content"
            :class="{ active: activeTab == 'favorites' }">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr v-for="(item, index) in wishlistItems.data" :key="item.index">
                        <td class="col-4 col-lg-2 pl-0">
                          <img class="img-fluid m-pointer" style="object-fit: contain" :src="item && item?.image[0]?.preview_thumbnail
                    ? item?.image[0]?.preview_thumbnail
                    : '/img/placeholders/block.png'
                    " @click.prevent="
                    router.push({
                      name: 'Artwork',
                      params: { id: item.id },
                    })
                    " alt="" loading="lazy" />
                        </td>
                        <td class="col-7 col-lg-9 align-middle">
                          <div class="m-pointer" @click.prevent="
                    router.push({
                      name: 'Artwork',
                      params: { id: item.id },
                    })
                    ">
                            <div class="header-2-s">
                              {{ item?.name_en }}
                            </div>
                            <div class="text-3-s d-none d-lg-block">
                              {{ item?.description_en }}
                            </div>
                          </div>
                        </td>
                        <td class="col-1 pr-0 align-middle">
                          <button class="btn-custom-light" @click.prevent="
                    deleteWishlistItem(item.id, isLoggedIn)
                    ">
                            <svg id="trash-delete-bin" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24">
                              <path id="Path_315" data-name="Path 315"
                                d="M6,9V19H6a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9m-4,1v7m-4-7v7M4.5,6h15M8,6l.544-1.632h0A2,2,0,0,1,10.442,3h3.116a2,2,0,0,1,1.9,1.368L16,6"
                                fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" />
                              <rect id="Rectangle_96" data-name="Rectangle 96" width="24" height="24" fill="none" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12">
                <div v-if="wishlistItems" class="d-flex justify-content-end">
                  <LaravelVuePagination :data="wishlistItems" :limit="1"
                    @pagination-change-page="(page) => getWishlistItems(page, isLoggedIn)" />
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeTab == 'settings'" id="settings" class="tab-content"
            :class="{ active: activeTab == 'settings' }">
            <div class="row">
              <div class="col-md-12">
                <form enctype="multipart/form-data" @submit.prevent="updateMyAccount(user)">
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <UploadFiles v-model="user.avatar" :placeholder="'img/user-defualt.svg'" :url="'/users/media'"
                        :collection_name="'user_avatar'" :size="2" :model_id="0" :upType="'avatar'" />
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Name</label>
                      <input v-model="user.name_en" class="form-control" id="name_en" type="text" name="name_en" />
                    </div>
                    <!-- <div class="col-md-6 mt-4">
                      <label class="form-control-label">username</label>
                      <input v-model="user.username" class="form-control" id="username" type="text" name="username" />
                    </div> -->
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Email</label>
                      <input v-model="user.email" class="form-control" id="email" type="text" name="email" />
                    </div>
                    <div class="col-md-6 mt-4">
                      <label class="form-control-label">Mobile</label>
                      <div class="mobile-input-container">
                        <Dropdown name="mobilecode" v-model="user.mobilecode" :filter="true"
                          :options="userMeta?.country" optionLabel="mobilecode" optionValue="mobilecode" id="mobilecode"
                          placeholder="Code" style="height: 2.905rem; width: 7rem;" />

                        <input v-model="user.mobile" class="form-control mobile-input" id="mobile" type="tel"
                          name="mobile" placeholder="+96600332244" />
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label">Bio</label>
                      <textarea v-model="user.about_en" class="form-control" name="about" id="about"
                        rows="5"></textarea>
                    </div>
                    <div class="col-md-12 mt-4">
                      <label class="form-control-label" style="display:none">IBAN</label>
                      <input v-model="user.iban" class="form-control" id="iban" type="text" name="iban"
                        style="display:none" />
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="
                                  py-4
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                ">
                        <label class="form-control-label">Address</label>
                        <div class="">
                          <button class="btn-custom-light ml-2" @click.prevent="addAddress(user?.address)">
                            <span class="text-bold-3-s">Add</span>
                          </button>
                        </div>
                      </div>
                      <div class="row m-0 justify-content-start w-100">
                        <div v-for="(address, index) in user?.address" :key="index"
                          class="col-12 col-md-12 col-lg-6 mb-4">
                          <div class="border">
                            <table class="table table-flush">
                              <thead class="thead-light">
                                <tr>
                                  <th colspan="12">
                                    <div class="w-100 d-flex justify-content-end">
                                      <button class="btn-custom-light ml-2" @click.prevent="
                    deleteUserAddress(
                      address.id,
                      index,
                      user.id
                    )
                    ">
                                        <svg id="trash-delete-bin" xmlns="http://www.w3.org/2000/svg" width="24"
                                          height="24" viewBox="0 0 24 24">
                                          <path id="Path_315" data-name="Path 315"
                                            d="M6,9V19H6a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9m-4,1v7m-4-7v7M4.5,6h15M8,6l.544-1.632h0A2,2,0,0,1,10.442,3h3.116a2,2,0,0,1,1.9,1.368L16,6"
                                            fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" />
                                          <rect id="Rectangle_96" data-name="Rectangle 96" width="24" height="24"
                                            fill="none" />
                                        </svg>
                                      </button>
                                    </div>
                                    <p class="text-wrap">
                                      <span
                                        v-if="userMeta?.country && userMeta?.country.filter((country) => address.country_id == country.id).length > 0">
                                        {{ userMeta?.country.filter((country) => address.country_id == country.id
                  )[0]?.name_en }},
                                      </span>
                                      <span
                                        v-if="userMeta?.city && userMeta?.city.filter((city) => address.city_id == city.id).length > 0">
                                        {{ userMeta?.city.filter((city) => address.city_id == city.id)[0]?.name_en }}
                                      </span>
                                      <span v-if="address.villa">
                                        Villa {{ address.villa }}
                                      </span>
                                      <span v-if="address.flat">
                                        Flat {{ address.flat }}
                                      </span>
                                      <span v-if="address.road">
                                        Road {{ address.road }}
                                      </span>
                                      <span v-if="address.block">
                                        Block {{ address.block }}
                                      </span>
                                    </p>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colspan="4">Villa</td>
                                  <td colspan="8">
                                    <input v-model="address.villa" class="form-control" id="address-villa" type="text"
                                      name="address-villa" />
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">Flat</td>
                                  <td colspan="8">
                                    <input v-model="address.flat" class="form-control" id="address-flat" type="text"
                                      name="address-flat" />
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">Road</td>
                                  <td colspan="8">
                                    <input v-model="address.road" class="form-control" id="address-road" type="text"
                                      name="address-road" />
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">Block</td>
                                  <td colspan="8">
                                    <input v-model="address.block" class="form-control" id="address-block" type="text"
                                      name="address-block" />
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">Post Code</td>
                                  <td colspan="8">
                                    <input v-model="address.post_code" class="form-control" id="address-post_code"
                                      type="text" name="address-post_code" />
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">Country</td>
                                  <td colspan="8">
                                    <Dropdown name="country_id" v-model="address.country_id" :filter="true"
                                      :options="userMeta?.country" optionLabel="name_en" optionValue="id"
                                      placeholder="Country" />
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">City</td>
                                  <td colspan="8">
                                    <Dropdown name="city_id" v-model="address.city_id" :filter="true" :options="userMeta?.city.filter(
                    (city) => city?.country_id == address?.country_id
                  )" optionLabel="name_en" optionValue="id" placeholder="City" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="
                                  py-4
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                ">
                        <label class="form-control-label">Social media</label>
                        <div class="">
                          <button class="btn-custom-light ml-2" @click.prevent="addSocial(user?.socials)">
                            <span class="text-bold-3-s">Add</span>
                          </button>
                        </div>
                      </div>
                      <div class="row m-0 justify-content-start w-100">
                        <div v-for="(social, index) in user?.socials" :key="index"
                          class="col-12 col-md-12 col-lg-6 mb-4">
                          <div class="border">
                            <table class="table table-flush">
                              <thead class="thead-light">
                                <tr>
                                  <th colspan="4">
                                    <h3>
                                      {{ index + 1 }}
                                    </h3>
                                  </th>
                                  <th colspan="8">
                                    <div class="w-100 d-flex justify-content-end">
                                      <button class="btn-custom-light ml-2" @click.prevent="
                    deleteUserSocial(social.id, index, user.id)
                    ">
                                        <svg id="trash-delete-bin" xmlns="http://www.w3.org/2000/svg" width="24"
                                          height="24" viewBox="0 0 24 24">
                                          <path id="Path_315" data-name="Path 315"
                                            d="M6,9V19H6a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V9m-4,1v7m-4-7v7M4.5,6h15M8,6l.544-1.632h0A2,2,0,0,1,10.442,3h3.116a2,2,0,0,1,1.9,1.368L16,6"
                                            fill="none" stroke="#1a1e1d" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" />
                                          <rect id="Rectangle_96" data-name="Rectangle 96" width="24" height="24"
                                            fill="none" />
                                        </svg>
                                      </button>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colspan="4">Link</td>
                                  <td colspan="8">
                                    <input v-model="social.link" class="form-control" id="social-link" type="url"
                                      name="social-link" />
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">Type</td>
                                  <td colspan="8">
                                    <Dropdown name="type_id" v-model="social.type_id" :options="userMeta?.social_types"
                                      optionLabel="name_en" optionValue="id" placeholder="Types" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4 d-flex justify-content-between">
                      <Button label="Cancel" @click.prevent="switchTab('profile')" class="p-button-secondary" />
                      <Button type="submit" label="Save" class="p-button-success" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, inject, ref, watch, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAbility } from "@casl/vue";

import { logedinUser, isLoggedIn } from "@/composables/auth";
import useUsers from "@/composables/Users";
import useArtworks from "@/composables/Artworks";
import { useShopping } from "@/composables/Shopping";

import InputSwitch from 'primevue/inputswitch';
import OverlayPanel from 'primevue/overlaypanel';
import UploadFiles from "@/components/UploadFiles";
import LaravelVuePagination from "@/components/Pagination/LaravelVuePagination.vue";

const page = ref(1);
const per_page = ref(12);
const per_page_options = ref([]);
const search_id = ref("");
const search_name_en = ref("");
const search_name_ar = ref("");
const search_categories = ref([]);
const search_products = ref([]);
const search_tags = ref([]);
const search_variations = ref([]);
const search_global = ref("");
const orderColumn = ref("created_at");
const orderColumnOptions = ref([]);
const orderDirection = ref("desc");
const orderDirectionOptions = ref([]);
const inactive = ref(1);

const activeTab = ref("profile");

const swal = inject("$swal");

const {
  getUser,
  user,
  userMeta,
  deleteUserAddress,
  deleteUserSocial,
  updateMyAccount,
  validationErrors,
  isLoading,
  readNotifications,
} = useUsers();
const { deleteArtwork, updateIsActive } = useArtworks();

const {
  cancelOrder,
  payOrder,
  addToWishlist,
  wishlistIDs,
  wishlistItems,
  getWishlistItems,
  deleteWishlistItem,
} = useShopping();

const router = useRouter();
const route = useRoute();
const { can } = useAbility();
const unreadNotifications = ref(0);

onMounted(async () => {
  await getUser(
    logedinUser.id,
    page.value,
    search_global.value,
    orderColumn.value,
    orderDirection.value,
    per_page.value
  );
  getWishlistItems(1, isLoggedIn.value);
  getUnreadNotifications()
  // activeTab.value = localStorage.getItem("account-tab")
  //   ? localStorage.getItem("account-tab")
  //   : "artwork";
  activeTab.value = "order-history";
  per_page_options.value = [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
  ];
  orderDirectionOptions.value = [
    { text: "ASC", value: "asc" },
    { text: "DESC", value: "desc" },
  ];
  orderColumnOptions.value = [
    { text: "Date", value: "created_at" },
    { text: "Name", value: "name_en" },
  ];

});

const ArtworkMenu = ref();

const toggleArtworkMenu = (event, index) => {
  ArtworkMenu.value[index].toggle(event);
};

watch(search_global, () => {
  getUser(
    logedinUser.id,
    1,
    search_global.value,
    orderColumn.value,
    orderDirection.value,
    per_page.value
  );
});

watch(orderColumn, () => {
  getUser(
    logedinUser.id,
    1,
    search_global.value,
    orderColumn.value,
    orderDirection.value,
    per_page.value
  );
});

const addAddress = async (address) => {
  if (address.length < 5) {
    address.push({
      villa: "",
      flat: "",
      road: "",
      block: "",
      post_code: "",
      country_id: "",
      city_id: "",
    });
  } else {
    swal({
      icon: "warning",
      title: "You reached the maximum number of addresses",
    });
  }
};

const addSocial = async (social) => {
  if (social.length < 5) {
    social.push({
      link: "",
      type_id: null,
    });
  } else {
    swal({
      icon: "warning",
      title: "You reached the maximum number of social media",
    });
  }
};

const formatDateYYYYMMDD = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const limit = (string = "", limit = 0) => {
  if (!string) return "";
  if (string.length <= limit) {
    return string.substring(0, limit);
  } else {
    return string.substring(0, limit) + " ...";
  }
};

const updateOrdering = (column) => {
  orderColumn.value = column;
  orderDirection.value = orderDirection.value === "asc" ? "desc" : "asc";
  getUser(
    logedinUser.id,
    1,
    search_global.value,
    orderColumn.value,
    orderDirection.value,
    per_page.value
  );
};

const switchTab = async (tabName) => {
  activeTab.value = tabName;
};

const daysAgo = (date) => {
  const date1 = new Date(date);
  const date2 = new Date();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  const diffh = Math.round(diffTime / (1000 * 60 * 60));
  const diffm = Math.round(diffTime / (1000 * 60));
  const diffs = Math.round(diffTime / 1000);
  if (diffs < 60) return diffs + "s ago";
  if (diffm < 60) return diffm + "m ago";
  if (diffh < 24) return diffh + "h ago";
  if (diffDays < 1) return diffDays + "day ago";
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date1).toLocaleDateString("en-us", options);
};

const cancelOrderHandle = async (order_id, status_id, index) => {
  await cancelOrder(order_id, status_id).then((response) => {
    user.value.orders.data[index] = response?.data?.data;
  }).catch((err) => {
    console.log(err)
  })
}

const getUnreadNotifications = () => {
  var data = []
  unreadNotifications.value = 0
  if (user.value?.notifications) {
    user.value?.notifications?.forEach(notification => {
      if (notification.pivot.is_read == 0) {
        data.push(notification.pivot)
        unreadNotifications.value++
      }
    });
  }
  return data
}

const handleDeleteArtwork = async (artworkId, submitType) => {
  await deleteArtwork(artworkId, submitType);
  await getUser(
    logedinUser.id,
    1,
    search_global.value,
    orderColumn.value,
    orderDirection.value,
    per_page.value
  );
}

watch(
  () => route.params,
  async () => {
    switchTab(route.params.toTab);
  },
  { deep: true }
);

watch(
  () => activeTab.value,
  async () => {
    if (activeTab.value == 'notifications') {
      var data = getUnreadNotifications()
      readNotifications({ notifications: data })
      unreadNotifications.value = 0
    }
  },
  { deep: true }
);

</script>
<style scoped>
.pad-0 {
  padding-right: 0 !important;
  padding-bottom: 1rem !important;
}

.color {
  height: 15px;
  width: 15px;
}

.mobile-input-container {
  display: flex;
  align-items: center;
}

.mobile-input {
  flex-grow: 1;
  /* Allow the first input to grow and take the available space */
  margin-right: 0.5rem;
  /* Add some spacing between the inputs */
}

.side-menu {
  display: flex;
  flex-direction: column;
}

.side-menu .menu-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.side-menu .menu-list .list-item {
  cursor: pointer;
  margin-top: 1rem;
  padding-left: calc(0.7rem + 4px);
  color: var(--gray-pxl);
}

.side-menu .menu-list .list-item.active {
  padding-left: 0.7rem;
  border-left: 4px solid var(--primary-pxl);
  color: var(--secondary-pxl);
  animation-name: fadeInBorder;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

.tab-content.active {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

.card-custom {
  width: 180px;
  height: 180px;
}

.card-custom .card-img {
  height: 100%;
}

.card-custom .card-title {
  font-family: "Poppins-Bold";
}
</style>
